import React from 'react'

const TelegramShare = (props) => (
  <svg viewBox="0 0 455.731 455.731" {...props}>
    <path fill="#61a8de" d="M0 0h455.731v455.731H0z" />
    <path
      d="M358.844 100.6L54.091 219.359c-9.871 3.847-9.273 18.012.888 21.012l77.441 22.868 28.901 91.706c3.019 9.579 15.158 12.483 22.185 5.308l40.039-40.882 78.56 57.665c9.614 7.057 23.306 1.814 25.747-9.859l52.031-248.76c2.548-12.185-9.44-22.337-21.039-17.817zm-38.208 55.206L179.08 280.984a7.603 7.603 0 0 0-2.519 4.847l-5.45 48.448c-.178 1.58-2.389 1.789-2.861.271l-22.423-72.253a7.605 7.605 0 0 1 3.255-8.717l167.163-103.676c3.844-2.386 7.78 2.906 4.391 5.902z"
      fill="#fff"
    />
  </svg>
)

export default TelegramShare
