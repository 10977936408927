import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Howl } from 'howler'
import Avatar from 'src/img/avatar.svg'
import styles from './PodcastCover.module.scss'
import SocialShare from 'src/components/SocialShare'
import { getBaseUrl } from 'src/utils'
import Img from 'gatsby-image'

function convertTime(seconds) {
  const date = new Date(null)
  date.setSeconds(seconds)
  return date.toISOString().substr(11, 8)
}

class PodcastCover extends Component {
  constructor(props) {
    super(props)
    this.state = {
      playing: false,
      speed: 1,
      seek: '0',
      displayLoading: true,
    }
  }

  handlePlayStop = () => {
    this.setState(
      {
        playing: !this.state.playing,
      },
      () => {
        if (this.state.playing) {
          this.player.play()
        } else {
          this.player.pause()
        }
        this.props.toggleIsPlaying(this.state.playing)
      },
    )
  }

  renderSeekPos = () => {
    this.setState({
      seek: this.player.seek(),
    })
    if (this.state.playing) {
      this.timerId = setTimeout(this.renderSeekPos, 1000)
    }
  }

  onChangeSeek = (e) => {
    const value = e.target.value
    this.setState({ seek: value }, () => {
      this.player.seek(value)
    })
  }

  clearSeekTimeout() {
    clearTimeout(this.timerId)
  }

  componentDidMount() {
    const { url } = this.props

    this.player = new Howl({
      src: url,
      html5: true,
      preload: false,
    })

    this.player.on('end', () => {
      this.setState({
        playing: false,
      })
      this.clearSeekTimeout()
    })

    this.player.on('play', () => {
      this.renderSeekPos()
    })

    this.player.on('load', () => {
      this.setState({
        displayLoading: false,
        duration: this.player.duration(),
      })
    })

    this.handlePlayStop()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.play !== this.props.play) {
      this.handlePlayStop()
    }
  }

  componentWillUnmount() {
    this.clearSeekTimeout()
    this.player.unload()
  }

  getUrlToShare = () => {
    if (this.props.parentTemplateKey && this.props.parentSlug) {
      return `${getBaseUrl(this.props.locale)}${this.props.locale}${this.props.parentTemplateKey}/${
        this.props.parentSlug
      }`
    } else {
      return `${getBaseUrl(this.props.locale)}${this.props.locale}/podcast/${this.props.parentSlug}`
    }
  }

  render() {
    const { className, title, cover, author, locale } = this.props
    const { displayLoading, seek, playing } = this.state

    return (
      <section className={classNames(className, styles.root)}>
        <div className={styles.wrap}>
          <div className={styles.buttons}>
            <button onClick={this.handlePlayStop} className={playing ? styles.playing : styles.paused} />
          </div>
          {displayLoading ? (
            <div className={styles.spinnerWrapper}>
              <span>Loading</span>
              <div className={styles.spinner}>
                <div className={styles.bounce1} />
                <div className={styles.bounce2} />
                <div className={styles.bounce3} />
              </div>
            </div>
          ) : (
            <div className={styles.range}>
              <p className={styles.time}>{seek !== undefined ? convertTime(seek) : '00:00:00'}</p>
              <label>
                <span>
                  <input
                    type="range"
                    min="0"
                    max={this.state.duration ? this.state.duration.toFixed(2) : '1'}
                    step="1"
                    value={seek}
                    onChange={this.onChangeSeek}
                    className={styles.rangeInput}
                  />
                </span>
              </label>
              <p className={styles.time}>{this.state.duration ? convertTime(this.state.duration) : ''}</p>
            </div>
          )}
          <div className={styles.podcastInfo}>
            <div className={styles.guest}>
              <div className={styles.podcastCover}>
                {cover ? <Img fluid={cover.fluid} className={styles.imgSizes} /> : <img src={Avatar} alt={title} />}
              </div>
              <div className={styles.podcastAbout}>
                <p className={styles.podcastAuthor}>{author.length > 15 ? `${author.substring(0, 15)}...` : author}</p>
                <h1 className={styles.title}>{title.length > 15 ? `${title.substring(0, 15)}...` : title}</h1>
              </div>
            </div>
            <SocialShare
              title={title}
              isWhiteFixed={true}
              url={this.getUrlToShare()}
              className={styles.share}
              locale={locale}
            />
            <button
              onClick={() => {
                this.props.handleClose(this.state.name)
              }}
              className={styles.podcastClose}
            />
          </div>
        </div>
      </section>
    )
  }
}

PodcastCover.defaultProps = {
  title: '',
}

PodcastCover.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  background: PropTypes.string,
  url: PropTypes.string,
}

export default PodcastCover
