import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Modal from 'react-modal'
import styles from './SocialShare.module.scss'
import ShareIcon from 'src/components/svg/ShareIcon'
import { AppContext } from 'src/components/Layout/Layout'
import { FacebookShareButton, LinkedinShareButton, TelegramShareButton } from 'react-share'
import FacebookShare from 'src/components/svg/FacebookShare'
import LinkedInShare from 'src/components/svg/LinkedinShare'
import TelegramShare from 'src/components/svg/TelegramShare'
import CopyIcon from 'src/components/svg/CopyIcon'

const customStyles = {
  overlay: {
    zIndex: '100',
  },
  content: {
    position: 'relative',
    padding: '0',
    border: 'none',
    width: 'calc(100% - 40px)',
    maxWidth: '640px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0',
  },
}

Modal.setAppElement('#___gatsby')

class SocialShare extends Component {
  state = {
    isModalOpen: this.props.isModalOpen || false,
    showNotification: false,
  }

  toggleModal = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ isModalOpen: !this.state.isModalOpen })
  }

  handleClickOnModal = (e) => {
    e.stopPropagation()
  }

  closeModal = (e) => {
    e.stopPropagation()
    this.setState({ isModalOpen: false })
  }

  inputRef = React.createRef()

  copyCurrentUrl = () => {
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      let editable = this.inputRef.current.contentEditable
      let readOnly = this.inputRef.current.readOnly

      this.inputRef.current.contentEditable = true
      this.inputRef.current.readOnly = true

      let range = document.createRange()
      range.selectNodeContents(this.inputRef.current)

      let selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
      this.inputRef.current.setSelectionRange(0, 999999)

      this.inputRef.current.contentEditable = editable
      this.inputRef.current.readOnly = readOnly
    } else {
      this.inputRef.current.select()
    }

    document.execCommand('copy')
    this.inputRef.current.setSelectionRange(0, 0)
    this.inputRef.current.blur()
    this.setState({ showNotification: true })

    this.timer = setTimeout(() => {
      this.setState({ showNotification: false })
    }, 1000)
    this.timer = null
  }

  render() {
    const { className, isSmall, isWhite, isSmallWhite, isWhiteFixed, url, title, isModalOpen, toggleModal } = this.props
    const { showNotification } = this.state

    return (
      <AppContext.Consumer>
        {(locale) => {
          return (
            <div>
              <div
                className={classNames(
                  className,
                  styles.root,
                  styles.btnshare,
                  { [styles.small]: isSmall },
                  { [styles.white]: isWhite },
                  { [styles.smallwhite]: isSmallWhite },
                  { [styles.whitefixed]: isWhiteFixed },
                )}
                onClick={toggleModal || this.toggleModal}
              >
                <ShareIcon width="32px" height="32px" className={styles.share} locale={this.props.locale} />
              </div>

              <Modal
                isOpen={isModalOpen || this.state.isModalOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                closeTimeoutMS={200}
                className={styles.modalAnimation}
              >
                <div className={styles.content} onClick={this.handleClickOnModal}>
                  <button className={styles.close} onClick={this.closeModal} />
                  <h2 className={styles.modalTitle}>
                    {locale === 'en' || this.props.locale === 'en' ? 'Share:' : 'Поделиться материалом:'}
                  </h2>
                  <div className={styles.title}>{title}</div>
                  <div className={classNames(styles.inner, styles.visible)}>
                    <div className={styles.buttons}>
                      <FacebookShareButton url={url} quote={title} className={styles.shareButton}>
                        <FacebookShare width="64px" height="64px" />
                      </FacebookShareButton>

                      <LinkedinShareButton url={url} quote={title} className={styles.shareButton}>
                        <LinkedInShare width="64px" height="64px" />
                      </LinkedinShareButton>

                      <TelegramShareButton url={url} title={title} className={styles.shareButton}>
                        <TelegramShare width="64px" height="64px" />
                      </TelegramShareButton>
                      <div className={styles.shareButton} onClick={this.copyCurrentUrl}>
                        {showNotification && (
                          <span className={styles.notification}>
                            {locale === 'en' || this.props.locale === 'en' ? 'Copied!' : 'Скопировано'}
                          </span>
                        )}
                        <CopyIcon />
                        <input className={styles.hiddenInput} value={url} readOnly ref={this.inputRef} />
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

SocialShare.propTypes = {
  className: PropTypes.string,
  isSmall: PropTypes.bool,
  isWhite: PropTypes.bool,
  isSmallWhite: PropTypes.bool,
  isWhiteFixed: PropTypes.bool,
  url: PropTypes.string,
  title: PropTypes.string,
  locale: PropTypes.string,
}

export default SocialShare
