import React from 'react'
import PodcastLayout from './src/components/PodcastLayout'

export const wrapPageElement = ({ element, props }) => {
  return <PodcastLayout {...props}>{element}</PodcastLayout>
}

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  // console.log({ location })

  if (location.hash) {
    return false
  }

  return true
}
