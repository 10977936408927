import React from 'react'

const LinkedinShare = (props) => (
  <svg viewBox="0 0 455.731 455.731" {...props}>
    <path fill="#0084b1" d="M0 0h455.731v455.731H0z" />
    <g fill="#fff">
      <path d="M107.255 69.215c20.873.017 38.088 17.257 38.043 38.234-.05 21.965-18.278 38.52-38.3 38.043-20.308.411-38.155-16.551-38.151-38.188 0-20.985 17.282-38.105 38.408-38.089zM129.431 386.471H84.71c-5.804 0-10.509-4.705-10.509-10.509V185.18c0-5.804 4.705-10.509 10.509-10.509h44.721c5.804 0 10.509 4.705 10.509 10.509v190.783c-.001 5.803-4.705 10.508-10.509 10.508zM386.884 241.682c0-39.996-32.423-72.42-72.42-72.42h-11.47c-21.882 0-41.214 10.918-52.842 27.606a64.249 64.249 0 0 0-3.52 5.658c-.373-.056-.594-.085-.599-.075v-23.418a4.363 4.363 0 0 0-4.363-4.363h-55.795a4.363 4.363 0 0 0-4.363 4.363V382.11a4.363 4.363 0 0 0 4.361 4.363l57.011.014a4.363 4.363 0 0 0 4.364-4.363V264.801c0-20.28 16.175-37.119 36.454-37.348 10.352-.117 19.737 4.031 26.501 10.799 6.675 6.671 10.802 15.895 10.802 26.079v117.808a4.363 4.363 0 0 0 4.361 4.363l57.152.014a4.363 4.363 0 0 0 4.364-4.363V241.682z" />
    </g>
  </svg>
)

export default LinkedinShare
