import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PodcastCover from 'src/components/PodcastCover'

export const PodcastContext = React.createContext()

class PodcastLayout extends Component {
  state = {
    url: '',
    title: '',
    author: '',
    cover: '',
    isPlaying: false,
    play: true,
    parentTemplateKey: '',
    parentSlug: '',
    podcastSlug: '',
  }

  handleClose = () => {
    this.setState({ url: '' })
  }

  handleChangeData = (url, title, author, cover, parentSlug, parentTemplateKey) => {
    this.setState({
      url,
      title,
      author,
      cover,
      parentSlug,
      parentTemplateKey,
    })
  }

  handleChangePlay = () => {
    this.setState((prevState) => {
      return {
        play: !prevState.play,
      }
    })
  }

  toggleIsPlaying = (isPlaying) => {
    this.setState({ isPlaying })
  }

  render() {
    const { children } = this.props
    return (
      <div>
        <PodcastContext.Provider
          value={{
            onChangeData: this.handleChangeData,
            isPlaying: this.state.isPlaying,
            currentUrl: this.state.url,
            onChangePlay: this.handleChangePlay,
          }}
        >
          <>{children}</>
        </PodcastContext.Provider>
        {this.state.url && (
          <PodcastCover
            handleClose={this.handleClose}
            key={this.state.url}
            title={this.state.title}
            url={this.state.url}
            cover={this.state.cover}
            author={this.state.author}
            toggleIsPlaying={this.toggleIsPlaying}
            play={this.state.play}
            parentTemplateKey={this.state.parentTemplateKey}
            parentSlug={this.state.parentSlug}
            podcastSlug={this.state.podcastSlug}
            locale={this.props.pageContext.locale}
          />
        )}
      </div>
    )
  }
}

PodcastLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PodcastLayout
