import { PROD_URL, DEV_URL } from 'src/constants'

const setTrueState = (thisisthis) => {
  thisisthis.setState({ isOnline: true })
}

const setFalseState = (thisisthis) => {
  thisisthis.setState({ isOnline: false })
}

export const isOnline = (thisisthis) => {
  if (typeof window !== 'undefined') {
    window.addEventListener('online', () => setTrueState(thisisthis))

    window.addEventListener('offline', () => setFalseState(thisisthis))
  }
}

export const removeListeners = () => {
  if (typeof window !== 'undefined') {
    window.removeEventListener('online', () => setTrueState())
    window.removeEventListener('offline', () => setFalseState())
  }
}

export const getLink = (locale, path = '') => {
  return locale === 'ru' ? `/ru/${path}` : `/en/${path}`
}

export const handleSortByDuration = (arr) => {
  return arr.sort((a, b) => transformDurationInSeconds(a.duration) - transformDurationInSeconds(b.duration))
}

export const handleSortByDate = (arr) => {
  return [].concat(arr).sort((a, b) => (a.publishDate > b.publishDate ? -1 : b.publishDate > a.publishDate ? 1 : 0))
}

export const handleSortByOldDate = (arr) => {
  return arr.sort((a, b) => a.node.dateToTime - b.node.dateToTime)
}

export const handleSortByNewDate = (arr) => {
  return arr.sort((a, b) => b.node.dateToTime - a.node.dateToTime)
}

const transformDurationInSeconds = (duration) => {
  return Number(duration.split(':').reduce((acc, time) => acc * 60 + Number(time)))
}

export const formateDate = (arr, publishdate) => {
  return arr.map((i) => {
    const tempArr = i.node[publishdate].split('.')
    let temp = tempArr[1]
    tempArr[1] = tempArr[0]
    tempArr[0] = temp
    const tempDate = tempArr.join('/')

    let dateToTime = new Date(tempDate).getTime()
    i.node.dateToTime = dateToTime
    return i
  })
}

export const shuffle = (array) => {
  let currentIndex = array.length
  let temporaryValue
  let randomIndex = 0
  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }
  return array
}

export const getBaseUrl = () => {
  if (process.env.NODE_ENV === 'development') {
    return `${DEV_URL}/`
  }
  return `${PROD_URL}/`
}

let counter = 0

export const getStyles = (array, length) => {
  if (counter >= length - 1) {
    counter = 0
  }
  counter++
  // return array[Math.floor(Math.random() * length)]
  return array[counter]
}

export const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const addMonths = (date, months) => new Date(date.setMonth(date.getMonth() + months))

export const colors = [
  { color: '#353533', bg: '#8AD3FB' },
  { color: '#353533', bg: '#E06C6C' },
  { color: '#353533', bg: '#4AAFB5' },
  { color: '#353533', bg: '#ECCB52' },
  { color: '#fff', bg: '#3F72AF' },
  { color: '#2D4059', bg: '#F07B3F' },
  { color: '#353533', bg: '#4AAFB5' },
  { color: '#00ADB5', bg: '#393E46' },
]

export const blobPositions = [
  { top: '-120px', left: '60%' },
  { top: '80px', left: '50%' },
  { top: '-100px', left: '55%' },
  { top: '-240px', left: '65%' },
  { top: '-150px', left: '50%' },
]
