// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-interview-js": () => import("/opt/build/repo/src/templates/Interview.js" /* webpackChunkName: "component---src-templates-interview-js" */),
  "component---src-templates-single-news-js": () => import("/opt/build/repo/src/templates/SingleNews.js" /* webpackChunkName: "component---src-templates-single-news-js" */),
  "component---src-templates-advice-js": () => import("/opt/build/repo/src/templates/Advice.js" /* webpackChunkName: "component---src-templates-advice-js" */),
  "component---src-pages-advice-categories-js": () => import("/opt/build/repo/src/pages/advice-categories.js" /* webpackChunkName: "component---src-pages-advice-categories-js" */),
  "component---src-templates-video-js": () => import("/opt/build/repo/src/templates/Video.js" /* webpackChunkName: "component---src-templates-video-js" */),
  "component---src-templates-podcast-js": () => import("/opt/build/repo/src/templates/Podcast.js" /* webpackChunkName: "component---src-templates-podcast-js" */),
  "component---src-templates-factory-js": () => import("/opt/build/repo/src/templates/Factory.js" /* webpackChunkName: "component---src-templates-factory-js" */),
  "component---src-templates-story-js": () => import("/opt/build/repo/src/templates/Story.js" /* webpackChunkName: "component---src-templates-story-js" */),
  "component---src-i-18-n-redirect-js": () => import("/opt/build/repo/src/i18n/redirect.js" /* webpackChunkName: "component---src-i-18-n-redirect-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-add-job-js": () => import("/opt/build/repo/src/pages/add-job.js" /* webpackChunkName: "component---src-pages-add-job-js" */),
  "component---src-pages-advices-js": () => import("/opt/build/repo/src/pages/advices.js" /* webpackChunkName: "component---src-pages-advices-js" */),
  "component---src-pages-become-a-hero-js": () => import("/opt/build/repo/src/pages/become-a-hero.js" /* webpackChunkName: "component---src-pages-become-a-hero-js" */),
  "component---src-pages-contacts-js": () => import("/opt/build/repo/src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-factory-js": () => import("/opt/build/repo/src/pages/factory.js" /* webpackChunkName: "component---src-pages-factory-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interviews-js": () => import("/opt/build/repo/src/pages/interviews.js" /* webpackChunkName: "component---src-pages-interviews-js" */),
  "component---src-pages-news-list-js": () => import("/opt/build/repo/src/pages/news-list.js" /* webpackChunkName: "component---src-pages-news-list-js" */),
  "component---src-pages-podcasts-js": () => import("/opt/build/repo/src/pages/podcasts.js" /* webpackChunkName: "component---src-pages-podcasts-js" */),
  "component---src-pages-stories-list-js": () => import("/opt/build/repo/src/pages/stories-list.js" /* webpackChunkName: "component---src-pages-stories-list-js" */),
  "component---src-pages-videos-js": () => import("/opt/build/repo/src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

