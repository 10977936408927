import React, { Component } from 'react'
import PropTypes from 'prop-types'
import 'src/styles/style.scss'

export const AppContext = React.createContext()
class Template extends Component {
  state = { lang: 'ru-RU' }

  render() {
    const { children, locale } = this.props

    return <AppContext.Provider value={locale}>{children}</AppContext.Provider>
  }
}

Template.propTypes = {
  children: PropTypes.array,
  locale: PropTypes.string.isRequired,
}

export default Template
