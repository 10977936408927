import React from 'react'

const CopyIcon = (props) => (
  <svg width="64" height="64" viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="400" height="400" fill="#9143FD" />
    <path
      d="M335.625 124.375C332.707 121.459 329.167 120 325.003 120H259.999C253.751 120 247.082 122.083 240.001 126.25V75.0019C240.001 70.8338 238.544 67.2897 235.626 64.3755C232.708 61.459 229.168 60 224.999 60H160.001C155.834 60 151.25 61.0415 146.25 63.1238C141.25 65.2068 137.293 67.7078 134.374 70.6248L70.6248 134.375C67.7078 137.291 65.2068 141.25 63.1238 146.25C61.0415 151.251 60 155.832 60 160.001V264.999C60 269.169 61.4574 272.712 64.3738 275.626C67.2908 278.544 70.8333 280.001 74.9986 280.001H160V325.003C160 329.167 161.457 332.711 164.374 335.625C167.289 338.543 170.832 340 174.999 340H325.001C329.166 340 332.71 338.543 335.624 335.625C338.542 332.711 339.999 329.167 339.999 325.003V135.002C340 130.835 338.543 127.292 335.625 124.375ZM240.001 153.282V200H193.282L240.001 153.282ZM139.999 93.2814V139.999H93.2819L139.999 93.2814ZM170.625 194.375C167.708 197.292 165.207 201.251 163.124 206.251C161.042 211.254 160.001 215.833 160.001 220.002V259.998H80.0012V160.001H144.999C149.167 160.001 152.708 158.543 155.625 155.626C158.54 152.709 160 149.167 160 145.002V80.0012H220.001V145.001L170.625 194.375ZM319.998 320.002H179.997V219.998H244.999C249.166 219.998 252.706 218.541 255.622 215.623C258.538 212.709 259.997 209.167 259.997 205.001V139.999H319.994V320.002H319.998Z"
      fill="white"
    />
  </svg>
)

export default CopyIcon
