module.exports = [{
      plugin: require('/opt/build/repo/plugins/local-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-144860803-1","anonymize":true,"respectDNT":true},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-144860803-1"],"pluginConfig":{"respectDNT":true}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"739008876538523"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","ru"],"fields":[{"name":"title","store":true,"attributes":{"boost":20}},{"name":"description","store":true},{"name":"body"},{"name":"url","store":true},{"name":"templateKey","store":true},{"name":"locale","store":true}],"resolvers":{"DatoCmsNews":{},"DatoCmsInterview":{}}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-images-datocms/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"c639e2a04da0c0c663a319b4a957bc","maxWidth":578},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":768},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
